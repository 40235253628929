<template>
  <div class="expCo">
    <h3>Опыт компании</h3>
    <div class="container-fluid ind">
      <div class="row ind">
        <!-- Карта ТРК МАРИ -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#mariModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/MARi.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">ТРК MARi</div>
        </div>
        <!-- Конец карты ТРК МАРИ -->
        <!-- Модальное окно ТРК МАРИ -->
        <div class="modal fade" id="mariModal" tabindex="-1" role="dialog" aria-labelledby="mariModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mariModalLabel">ТРК MARi</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Восстановление автоматики и диспетчеризации</li>
                  <li>Обслуживание системы АСДУ</li>
                  <li>Обслуживание локальной автоматики инженерных систем</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального окна ТРК МАРИ -->
        <!-- Карточка Отель "Marriott" -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#mariottModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/mariott.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">Отель "Marriott"</div>
        </div>
        <!-- Конец карты Отель "Marriott" -->
        <!-- Модальное окно Отель "Marriott" -->
        <div class="modal fade" id="mariottModal" tabindex="-1" role="dialog" aria-labelledby="mariottModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mariottModalLabel">Отель "Marriott"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Обслуживание автоматики общеобменной вентиляции</li>
                  <li>Обслуживание автоматики кондиционирования</li>
                  <li>Обслуживание автоматики холодоснабжения</li>
                  <li>Обслуживание системы АСДУ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального Отель "Marriott" -->
        <!-- Карточка МФК Ханой-Москва -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#hanoyModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/hanoy.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">МФК "Ханой-Москва"</div>
        </div>
        <!-- Конец карты МФК Ханой-Москва -->
        <!-- Модальное окно МФК Ханой-Москва -->
        <div class="modal fade" id="hanoyModal" tabindex="-1" role="dialog" aria-labelledby="hanoyModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="hanoyModalLabel">МФК "Ханой-Москва"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Обследование систем автоматики и диспетчеризации</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального МФК Ханой-Москва -->
        <!-- Карточка АРТ Резиденс -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#arModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/artResidence.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">ЖК "Арт Резиденс"</div>
        </div>
        <!-- Конец карты АРТ Резиденс -->
        <!-- Модальное окно АРТ Резиденс -->
        <div class="modal fade" id="arModal" tabindex="-1" role="dialog" aria-labelledby="arModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="arModalLabel">ЖК "Арт Резиденс"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Обслуживание автоматики общеобменной вентиляции</li>
                  <li>Обслуживание автоматики ЦТП</li>
                  <li>Обслуживание автоматики холодоснабжения</li>
                  <li>Обслуживание системы АСДУ</li>
                  <li>Обслуживание система АСКУЭ</li>
                  <li>Обслуживание системы диспетчеризации лифтового оборудования</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального АРТ Резиденс -->
        <!-- Карточка ПКК Миландр -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#milandrModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/milandr.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">ПКК "Миландр"</div>
        </div>
        <!-- Конец карты ПКК Миландр -->
        <!-- Модальное окно ПКК Миландр -->
        <div class="modal fade" id="milandrModal" tabindex="-1" role="dialog" aria-labelledby="milandrModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="milandrModalLabel">ПКК "Миландр"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Обслуживание автоматики общеобменной вентиляции сверхчистых помещений</li>
                  <li>Обслуживание автоматики холодоснабжения</li>
                  <li>Обслуживание системы АСДУ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального ПКК Миландр -->
        <!-- Карточка БЦ ДОЗ ГБУ Ритуал -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#ritualModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/ritual.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">ДОЗ ГБУ Ритуал</div>
        </div>
        <!-- Конец карты ДОЗ ГБУ Ритуал -->
        <!-- Модальное окно ДОЗ ГБУ Ритуал -->
        <div class="modal fade" id="ritualModal" tabindex="-1" role="dialog" aria-labelledby="ritualModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="ritualModalLabel">ДОЗ ГБУ Ритуал (по адресу: г.
                  Москва, Савеловский проезд, д. 10)
                </h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Разработка и программирование системы АСДУ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального ДОЗ ГБУ Ритуал -->
      </div>
      <div class="row ind">
        <!-- Карточка Креди Агриколь -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#creditAgricoleModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/creditAgricole.png" class="card-img" alt="...">
          </div>
          <div class="card-header">Креди Агриколь</div>
        </div>
        <!-- Конец карты Креди Агриколь -->
        <!-- Модальное окно Креди Агриколь -->
        <div class="modal fade" id="creditAgricoleModal" tabindex="-1" role="dialog" aria-labelledby="creditAgricoleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="creditAgricoleModalLabel">Креди Агриколь</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Обслуживание автоматики общеобменной вентиляции</li>
                  <li>Обслуживание автоматики ИТП</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального Креди Агриколь -->
        <!-- Карточка Barclayes Bank -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#barclayesModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/barclayes.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">Офис "Barclayes Bank"</div>
        </div>
        <!-- Конец карты Barclayes Bank -->
        <!-- Модальное окно Barclayes Bank -->
        <div class="modal fade" id="barclayesModal" tabindex="-1" role="dialog" aria-labelledby="barclayesModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="barclayesModalLabel">Офис "Barclayes Bank"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Обслуживание автоматики общеобменной вентиляции</li>
                  <li>Обслуживание автоматики кондиционирования</li>
                  <li>Обслуживание автоматики холодоснабжения</li>
                  <li>Обслуживание автоматики конвекторов отопления</li>
                  <li>Обслуживание системы контроля протечек</li>
                  <li>Обслуживание системы АСДУ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального Barclayes Bank -->
        <!-- Карточка БЦ "5 Морей" -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#sea5Modal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/5sea.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">БЦ "5 Морей"</div>
        </div>
        <!-- Конец карты БЦ "5 Морей" -->
        <!-- Модальное окно БЦ "5 Морей" -->
        <div class="modal fade" id="sea5Modal" tabindex="-1" role="dialog" aria-labelledby="sea5ModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="sea5ModalLabel">БЦ "5 Морей"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Восстановление системы АСДУ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального БЦ "5 Морей" -->
        <!-- Карточка Санаторий Истра -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#istraModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/istra.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">Санаторий РОП Истра</div>
        </div>
        <!-- Конец карты Санаторий Истра -->
        <!-- Модальное окно Санаторий Истра -->
        <div class="modal fade" id="istraModal" tabindex="-1" role="dialog" aria-labelledby="istraModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="istraModalLabel">Санаторий РОП Истра</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>СМР  и ПНР по автоматики общеобменной вентиляции</li>
                  <li>СМР  и ПНР по автоматики ИТП</li>
                  <li>СМР и ПНР по  автоматики холодоснабжения</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального Санаторий Истра -->
        <!-- Карточка ЖК "Jazz" -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#jazzModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/jazz.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">ЖК "Jazz"</div>
        </div>
        <!-- Конец карты ЖК "Jazz" -->
        <!-- Модальное окно ЖК "Jazz" -->
        <div class="modal fade" id="jazzModal" tabindex="-1" role="dialog" aria-labelledby="jazzModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="jazzModalLabel">ЖК "Jazz"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>В разработке</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального ЖК "Jazz" -->
        <!-- Карточка БЦ на Дмитровской 71 -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#dmitrovka71Modal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/dmitrovka71.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">БЦ на Дмитровской, 71</div>
        </div>
        <!-- Конец карты БЦ на Дмитровской 71 -->
        <!-- Модальное окно БЦ на Дмитровской 71 -->
        <div class="modal fade" id="dmitrovka71Modal" tabindex="-1" role="dialog" aria-labelledby="dmitrovka71ModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="dmitrovka71ModalLabel">БЦ на Дмитровской, 71</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Разработка и программирование системы АСДУ</li>
                </ul>
                <p>Модернизация автоматики холодоснабжения:</p>
                <ul class="listExp">
                  <li>Разработка технических решений</li>
                  <li>Поставка оборудования</li>
                  <li>Программирование и наладка автоматики</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального БЦ на Дмитровской 71 -->
      </div>
      <div class="row ind">
        <!-- Карточка БЦ "Ян-Рон" -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#yanronModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/yanron.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">БЦ "Ян-Рон"</div>
        </div>
        <!-- Конец карты БЦ "Ян-Рон" -->
        <!-- Модальное окно БЦ "Ян-Рон" -->
        <div class="modal fade" id="yanronModal" tabindex="-1" role="dialog" aria-labelledby="yanronModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="yanronModalLabel">БЦ "Ян-Рон"</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Восстановление работоспособности ИТП</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального БЦ "Ян-Рон" -->
        <!-- Карточка БЦ на ГБУК г.Москвы «Музей Зеленограда» -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#museumZelModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">

          </div>
          <div class="card-header">ГБУК «Музей Зеленограда»</div>
        </div>
        <!-- Конец карты ГБУК г.Москвы «Музей Зеленограда» -->
        <!-- Модальное окно ГБУК г.Москвы «Музей Зеленограда» -->
        <div class="modal fade" id="museumZelModal" tabindex="-1" role="dialog" aria-labelledby="museumZelModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="museumZelModalLabel">ГБУК г.Москвы «Музей Зеленограда»</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Разработка и программирование системы АСДУ</li>
                  <li>Поставка шкафов автоматики</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального ГБУК г.Москвы «Музей Зеленограда» -->
        <!-- Карточка Объект по адресу: п. Краснопахорское, кв-л 107, стр. 1.1; 1.2; 1.3; 1.4; 1.5 -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#pahraModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">

          </div>
          <div class="card-header">п. Краснопахорское, кв-л 107</div>
        </div>
        <!-- Конец карты Объект по адресу: п. Краснопахорское, кв-л 107, стр. 1.1; 1.2; 1.3; 1.4; 1.5 -->
        <!-- Модальное окно Объект по адресу: п. Краснопахорское, кв-л 107, стр. 1.1; 1.2; 1.3; 1.4; 1.5 -->
        <div class="modal fade" id="pahraModal" tabindex="-1" role="dialog" aria-labelledby="pahraModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="pahraModalLabel">п. Краснопахорское, кв-л 107, стр. 1.1; 1.2; 1.3; 1.4; 1.5</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Аудит выполненных работ по автоматике и инженерным системам</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального Объект по адресу: п. Краснопахорское, кв-л 107, стр. 1.1; 1.2; 1.3; 1.4; 1.5 -->
        <!-- Карточка БЦ на ТЦ «Скарабей» -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#scarabeyModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/scarabey.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">ТЦ «Скарабей»</div>
        </div>
        <!-- Конец карты ТЦ «Скарабей» -->
        <!-- Модальное окно ТЦ «Скарабей» -->
        <div class="modal fade" id="scarabeyModal" tabindex="-1" role="dialog" aria-labelledby="scarabeyModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="scarabeyModalLabel">ТЦ «Скарабей»</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Монтаж автоматики общеобменной вентиляции</li>
                  <li>Поставка шкафов автоматики и оборудования</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального ТЦ «Скарабей» -->
        <!-- Карточка ТЦ «Драйв» -->
        <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#driveModal" style="max-width: 21rem;">
          <div class="card-body text-dark ind">
            <img src="../assets/companyExp/objectsCo/drive.jpg" class="card-img" alt="...">
          </div>
          <div class="card-header">ТЦ «Драйв»</div>
        </div>
        <!-- Конец карты ТЦ «Драйв» -->
        <!-- Модальное окно ТЦ «Драйв» -->
        <div class="modal fade" id="driveModal" tabindex="-1" role="dialog" aria-labelledby="driveModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="driveModalLabel">ТЦ «Драйв»</h5>
              </div>
              <div class="modal-body text-left">
                <ul class="listExp">
                  <li>Монтаж автоматики общеобменной вентиляции</li>
                  <li>Поставка шкафов автоматики и оборудования</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Конец модального ТЦ «Драйв» -->
      </div>
    </div>
    <div class="container-fluid">
      <h3>Сертификаты</h3>
      <div id="certificateSlideCO" class="carousel-fade carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#certificateSlideCO" data-slide-to="0" class="active"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../assets/companyExp/certificate1.jpg" class="d-block w-100" alt="...">
          </div>
        </div>
        <a class="carousel-control-prev" href="#certificateSlideCO" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Предыдущий</span>
        </a>
        <a class="carousel-control-next" href="#certificateSlideCO" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Следующий</span>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

#certificateSlideCO {
  margin: auto;
  max-width: 450px;
}

.expCo {
  h3 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.cardSys {
  width: 500px;
  height: 280px;
  border: none;
}

.listExp {
  padding:0;
  list-style: none;
  li {
    padding: 6px;
  }
  li:before {
    padding-right:10px;
    font-weight: bold;
    color: #C0C0C0;
    content: "\2714";
    transition-duration: 0.5s;
  }
  li:hover:before {
    color: #ff6d4d;
    content: "\2714";
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5935' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff5935' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators .active{
  transition: 1s;
  background-color: #ff5935;
}

</style>